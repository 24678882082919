import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { fetchHealthCareProfessionals, fetchProfessionTypes } from "../../state"
import { Layout } from "../../components/Layout"
import { DoctorsSection as HealthCareProfessionalsSection } from "../../style/doctors"
import { HealthCareProfessionalTable } from "../../components/HealthCareProfessionalTable"
import { BallBeat } from "react-pure-loaders"

function HealthCareProfessionalsContainer({
  healthCareProfessionalsData,
  fetchHealthCareProfessionals,
  fetchProfessionTypes,
  user = {},
}) {
  const dispatch = useDispatch()

  const [professionType, setProfessionType] = useState(0)
  const createOptions = (inputArray) => {
    var filteredInputArray = inputArray
      .filter((item) => item.lookupId !== 100240001)
      .filter((item) => item.lookupId !== 100240002)
    // console.log(filteredInputArray)
    return filteredInputArray.map((type) => (
      <option value={Number(type.lookupId)} key={type.lookupId}>
        {type.lookupValue} ({type.lookupDescription}){" "}
      </option>
    ))
  }
  const handleChangeProfessionType = (value) => {
    const professionId = Number(value)
    setProfessionType(professionId)
    fetchHealthCareProfessionals(professionId, user)
  }
  useEffect(() => {
    // console.log("fetchHealthCareProfessionals being called")
    // if (healthCareProfessionalsData.healthcareprofessionals.length === 0) {
    //   fetchHealthCareProfessionals()
    // }
    if (healthCareProfessionalsData.professiontypelookups.length === 0) {
      fetchProfessionTypes()
    }
  }, [fetchProfessionTypes])

  return (
    <Layout>
      <HealthCareProfessionalsSection>
        <h1>Health Care Professionals</h1>

        <select
          style={{
            backgroundColor: "none",
            borderColor: "gray",
            fontSize: ".9rem",
            height: "2rem",
            margin: "1rem 0",
          }}
          defaultValue={professionType}
          onChange={(e) => handleChangeProfessionType(e.target.value)}
        >
          <option>Select a profession type</option>
          {createOptions(healthCareProfessionalsData.professiontypelookups)}
        </select>
        {healthCareProfessionalsData.loading ? (
          <h4>
            Loading Health Care Professionals{" "}
            <span>
              <BallBeat
                color={"#123abc"}
                loading={healthCareProfessionalsData.loading}
              />
            </span>{" "}
          </h4>
        ) : healthCareProfessionalsData.error ||
          healthCareProfessionalsData.professiontypeserror ? (
          <>
            <h4>ERROR ⚠️ FETCHING </h4>
            <br />
            <p style={{ color: "grey" }}>
              {healthCareProfessionalsData.error ||
                healthCareProfessionalsData.professiontypeserror}
            </p>
            <br />
            <br />
          </>
        ) : (
          <>
            <br />
            {/* <a
              style={{ textDecoration: "underline" }}
              href="https://www.mciindia.org/CMS/information-desk/indian-medical-register"
            >
              MCI India.org
            </a> */}
            <HealthCareProfessionalTable
              healthcareprofessionals={
                healthCareProfessionalsData.healthcareprofessionals
              }
              professionType={professionType}
            />
          </>
        )}
      </HealthCareProfessionalsSection>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    healthCareProfessionalsData: state.healthcareprofessionals,
    user: state.login.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHealthCareProfessionals: (professionId, user) =>
      dispatch(fetchHealthCareProfessionals(professionId, user)),
    fetchProfessionTypes: () => dispatch(fetchProfessionTypes()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthCareProfessionalsContainer)
