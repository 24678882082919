import React from "react"
import { Table } from "./Table"
import { TableStyles } from "../style/globalStyles"

export const HealthCareProfessionalTable = ({
  healthcareprofessionals = [],
  professionType = 0,
}) => {
  const columns = [
    {
      Header: "ID",
      accessor: "professionalId",
    },
    {
      Header: "Name",
      accessor: (healthcareprofessional) =>
        `${healthcareprofessional.firstName} ${healthcareprofessional.lastName}`,
    },

    {
      Header: "Clinic",
      accessor: (healthcareprofessional) =>
        healthcareprofessional.clinic
          ? `${healthcareprofessional.clinic.clinicName}` +
            " " +
            `${healthcareprofessional.clinic.clinicId}`
          : "NA",
    },

    // {
    //   Header: "Gender",
    //   accessor: "genderDesc",
    // },
    {
      Header: "Phone No.",
      accessor: "mobileNo",
    },
    // {
    //   Header: "E-mail",
    //   accessor: "email",
    // },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "Active",
      accessor: (isActive) => {
        return isActive.isActive ? "Active   " : "Not Active    "
      },
    },
    {
      Header: "Verified",
      accessor: (isVerified) => {
        return isVerified.isVerified ? "Verified   " : "Not Verified    "
      },
    },
    {
      Header: "Subscription",
      accessor: (isSubscription) => {
        return isSubscription.isSubscription
          ? "Subscribed   "
          : "No Subscription    "
      },
    },
  ]

  var data = React.useMemo(() => healthcareprofessionals, [])

  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          onClick: (e) => console.log("row clicked", rowInfo),
        }
      }}
      // Pass the page url here to navigate to onClick
      path={"/healthcareprofessionals/healthcareprofessional"}
      columns={columns}
      data={healthcareprofessionals}
    />
  )

  return healthcareprofessionals.length !== 0 ? (
    <TableStyles>{tableRender}</TableStyles>
  ) : professionType !== 0 ? (
    <>
      <br />
      <br />
      <h4>No healthcareprofessionals found</h4>
    </>
  ) : (
    ""
  )
}
